input[type="checkbox"] + label span {
    transition: background 0.2s, transform 0.2s;
}
/*input[type="checkbox"] + label span:hover, input[type="checkbox"] + label:hover span {*/
/*    transform: scale(1.2);*/
/*}*/
input[type="checkbox"]:checked + label span {
    background: linear-gradient(90deg, #DFAF3F 0%, #E15C5C 100%);
    box-shadow: 0px 0px 0px 2px #283142 inset;
    border: 2px solid #DFAF3F;
}
input[type="checkbox"]:checked + label {
    color: #DFAF3F;
}


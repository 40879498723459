form {
    min-width: 80%;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
    /*background: rgb(55 66 81);*/

}
input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
}
.result-message {
    line-height: 22px;
    font-size: 16px;
}
.result-message a {
    font-weight: 600;
    text-decoration: none;
}
.hidden {
    display: none;
}
#card-error {
    color: rgba(255, 255, 255, 0.3);
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
    text-align: center;
}

#payment-request-button {
    margin-bottom: 32px;
}
/* Buttons and links */
button {
    border-radius: 26px;
    width: 100%;
    height: 44px;
    background: linear-gradient(90deg, #DFAF3F 0%, #E15C5C 100%);
    color: #ffffff;
    font-family: Arial, sans-serif;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;

}

button:hover {
    filter: contrast(115%);
}
button:disabled {
    cursor: default;
    background: linear-gradient(90deg, darkgray 0%, dimgrey 100%);
}
/* spinner/processing state, errors */



/*.spinner,*/
/*.spinner:before,*/
/*.spinner:after {*/
/*    border-radius: 50%;*/
/*}*/
/*.spinner {*/
/*    color: #ffffff;*/
/*    font-size: 22px;*/
/*    text-indent: -99999px;*/
/*    margin: 0px auto;*/
/*    position: relative;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    box-shadow: inset 0 0 0 2px;*/
/*    -webkit-transform: translateZ(0);*/
/*    -ms-transform: translateZ(0);*/
/*    transform: translateZ(0);*/
/*}*/
/*.spinner:before,*/
/*.spinner:after {*/
/*    position: absolute;*/
/*    content: "";*/
/*}*/
/*.spinner:before {*/
/*    width: 10.4px;*/
/*    height: 20.4px;*/
/*    background: #dedede;*/
/*    border-radius: 20.4px 0 0 20.4px;*/
/*    top: -0.2px;*/
/*    left: -0.2px;*/
/*    -webkit-transform-origin: 10.4px 10.2px;*/
/*    transform-origin: 10.4px 10.2px;*/
/*    -webkit-animation: loading 2s infinite ease 1.5s;*/
/*    animation: loading 2s infinite ease 1.5s;*/
/*}*/
/*.spinner:after {*/
/*    width: 10.4px;*/
/*    height: 10.2px;*/
/*    background: #e9e9e9;*/
/*    border-radius: 0 10.2px 10.2px 0;*/
/*    top: -0.1px;*/
/*    left: 10.2px;*/
/*    -webkit-transform-origin: 0px 10.2px;*/
/*    transform-origin: 0px 10.2px;*/
/*    -webkit-animation: loading 2s infinite ease;*/
/*    animation: loading 2s infinite ease;*/
/*}*/
/*@-webkit-keyframes loading {*/
/*    0% {*/
/*        -webkit-transform: rotate(0deg);*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: rotate(360deg);*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/
/*@keyframes loading {*/
/*    0% {*/
/*        -webkit-transform: rotate(0deg);*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: rotate(360deg);*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/




#card-number {
    border-radius: 6px;
    margin-bottom: 6px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    color: #dedede;
    padding-left: 17px;
    padding-top: 7px;
}

#card-expiry {
    border-radius: 6px;
    margin-bottom: 6px;
    font-size: 16px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    color: #dedede;
    padding-left: 17px;
    padding-top: 7px;
}

#card-cvc {
    border-radius: 6px;
    margin-bottom: 6px;
    font-size: 16px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    color: #dedede;
    padding-left: 17px;
    padding-top: 7px;
}

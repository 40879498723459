.header {
    display: flex;
    justify-content: space-between;
    width: 375px;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.languageWrapper {
    display: flex;
    order: revert;
    width: 70px;
}


.buttonStyle {
    width: 40px;
    height: 20px;
    color: inherit;
    justify-content: center;
    background: transparent;
    border: 0;
    padding: 0 0 0 10px;
    font: inherit;
    cursor: pointer;
    right: 9%;
    outline: none;
    align-self: flex-end;
    margin-bottom: 3px;

}:focus {
     outline: 0 !important;
    background: none;
 }

.language {
    font-size: 16px;
    order: 0;
    color: lightgray;
    align-self: flex-end;
}



@media screen and (min-width: 640px) and (max-width: 1023px) {
    .header {
        width: 84%;
    }
}


@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .header {
        width: 72%;
    }
}

@media screen and (min-width: 1441px) {
    .header {
        width: 42%;
    }
}


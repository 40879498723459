.popupContainer {
    padding: 26px 20px 26px 19px;
    display: block;
}

.popupImage {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin: 10px auto;
    background-image: url("../../img/svg/success.svg");
    background-size: contain;
}

input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 25px;
    width: 100%;
    background: #313D4D;
    box-sizing: border-box;
}
::placeholder {
    color: red;
    font-size: 16px;
}